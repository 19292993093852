import Autocomplete from "@mui/material/Autocomplete";
import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { ERROR_CODE_SUCCESS } from "../../components/common/constant/ResponseErrorCodeConstant";
import { searchStoreLikeDto } from "../../service/StoreRequest";

const index = 1;
const pageSize = 10;

export default function StoreIdSelect({ setStoreIdChange, store ,clearSelectStoreId,storeLabelText}) {
  const [storeText, setStoreText] = useState(storeLabelText);
  const [data, setData] = useState([]);
  const storeTextChange = (event, storeTextValue) => {
 
    console.log("storeTextChange==storeTextValue===", storeTextValue);
    if (storeTextValue) {
      initStoreData(index, pageSize, storeTextValue);
    }
    if(clearSelectStoreId){
      clearSelectStoreId();
    }
    
  };
  const storeTextOnChange = (event, newStoreText, detail) => {
   
   
    console.log("storeTextOnChange==newStoreText===", newStoreText);
    setStoreText(newStoreText);
    setStoreIdChange(newStoreText);
  };

  useEffect(() => {
    // Update the document title using the browser API
    if (store) {
      setStoreText(store);
    }
    initStoreData(index, pageSize, "");
   
  }, []);

  const initStoreData = async (index, pageSize, searchText) => {
    try {
      
      let storeDataResponse = await searchStoreLikeDto(
        index,
        pageSize,
        searchText,
      );

      console.log("storeDataResponse===", storeDataResponse);
      let responseCode = storeDataResponse.code;
      if (responseCode == ERROR_CODE_SUCCESS) {
        let dataRecord = storeDataResponse.data;
        if(dataRecord){
          setData(dataRecord);
        }else{
          setData([]);

        }
        
      }
    } catch (e) {
      console.log("initStoreData==", e);
    }
  };
  return (
    <Stack spacing={2} sx={{ width: 300 }}>
      <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        options={data}
        getOptionLabel={(option) => {
          if(!option) return "";
          
          if (typeof option === "string") {
            return option;
          }
          return option.name;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Store info"
            InputProps={{
              ...params.InputProps,
              type: "search",
            }}
          />
        )}
        onInputChange={(event, storeTextValue) =>
          storeTextChange(event, storeTextValue)
        }
        value={storeText}
        onChange={(event, newStoreText, detail) => {
          storeTextOnChange(event, newStoreText, detail);
        }}
      />
    </Stack>
  );
}
