import React, { useState } from "react";
import { updateTransactionMonitorDataDto } from "../../service/TransactionMonitorDataRequest";

import {TextField, Button,Box, Container,makeStyles,Grid,Typography,Paper,
    Avatar,Select,FormControl,MenuItem,InputLabel
  } from "@material-ui/core";
  import { styled } from '@mui/material/styles';

  import CloudUploadIcon from '@mui/icons-material/CloudUpload';

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      overflow: 'hidden',
      padding: theme.spacing(0, 3),
    },
    paper: {
      maxWidth: 800,
      margin: `${theme.spacing(1)}px auto`,
      padding: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
  }));

  const message = `City chain@bugin junctions `;

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });


export  default function TransactionMonitorDataDetail ({handleEditClose,handleEditSubmit,editTransactionDto}){
   
        const classes = useStyles();

  
        console.log("editTransactionDto==",editTransactionDto.id);
        const [id,setId] = useState(editTransactionDto.id);
        const [paymentStatus,setPaymentStatus] = useState(editTransactionDto.paymentStatus);
        
 
        const [auditStatus, setAuditStatus] = useState(editTransactionDto.auditStatus);

        const [supplementRemark,setSupplementRemark] = useState(editTransactionDto.supplementRemark);
        const [auditPerson,setAuditPerson] = useState(editTransactionDto.auditPerson);
        const handleAuditStatusChange = (event) => {
            setAuditStatus(event.target.value);
        };

        const handleSupplementRemarkChange = (event) => {
            setSupplementRemark(event.target.value);
        };


        const handleAuditPersonChange = (event) => {
            setAuditPerson(event.target.value);
        };
         
        return (
            <div>

            <Paper className={classes.paper}  >
                <Grid container spacing={2}  >
                    <Grid item  >
                    <Typography  > Company Name:</Typography>
                    </Grid>
                    <Grid item xs zeroMinWidth  >
                        <Typography  >{editTransactionDto.buisnessName}</Typography>
                    </Grid>
                    </Grid>
                </Paper>
                <Paper className={classes.paper}>
                    <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                    <Typography>Store Name:</Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography noWrap>{editTransactionDto.storeName}</Typography>
                    </Grid>
                    </Grid>
                </Paper>
                <Paper className={classes.paper}>
                    <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                    <Typography  > Amount:</Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography>{editTransactionDto.amount}</Typography>
                    </Grid>
                    </Grid>
                </Paper>

                <Paper className={classes.paper}>
                    <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                    <Typography  > AuditStatus:</Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography>{editTransactionDto.auditStatus}</Typography>
                    </Grid>
                    </Grid>
                </Paper>

                <Paper className={classes.paper}>
                    <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                    <Typography  > Comment:</Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography>{editTransactionDto.supplementRemark}</Typography>
                    </Grid>
                    </Grid>
                </Paper>
               

        
                <Paper className={classes.paper}>
                    <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                    <Typography  > Auditor:</Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography>{editTransactionDto.auditPerson}</Typography>
                    </Grid>
                    </Grid>
                </Paper>
               

               
                
                
                <Paper className={classes.paper}>
                     
                
                <Grid container wrap="nowrap" spacing={2}>
                     
                    <Grid item xs>
                    <Button variant="contained" onClick={handleEditClose} >
                            Close
                    </Button>
                    </Grid>
                    </Grid>
                  
              
                </Paper>
                  
 
             
            </div>
        );
   
}

  