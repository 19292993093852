export const TERMINAL_STATUS = "new";
export const USERDTO = "userDto";
export const USER_TYPE_COMPANY = "company";
export const USER_TYPE_MANAGE = "manage";

export const PAYMENT_STATUS_SUCCESS = "success";

export const PAYMENT_REFUND_STATUS_PARTIAL_REFUND = "partial_refund";

export const ALIPAY_STATIC_TITLE = "Alipay static QrCode";

export const WECHAT_H5_TITLE = "Wechat H5 QrCode";

export const WECHAT_H5_WARNING_MESSAGE = "Please scan mobile browser !";


 
export const STORE_DCS_CONFIGURATION_STORE_ID_ERROR = "Store is required";
export const STORE_DCS_CONFIGURATION_DCS_MERCHANT_ID_ERROR = "DcsMerchantId is required";
export const STORE_DCS_CONFIGURATION_DCS_TERMINAL_ID_ERROR = "DcsTerminalId is required";
export const STORE_DCS_CONFIGURATION_DCS_SUB_MERCHANT_ID_ERROR = "DcsSubMerchantId is required";
export const STORE_DCS_CONFIGURATION_DCS_SUB_TERMINAL_ID_ERROR = "DcsSubTerminalId is required";
export const STORE_DCS_CONFIGURATION_DCS_SUB_MERCHANT_NAME_ERROR = "DcsSubMerchantName is required";
export const STORE_DCS_CONFIGURATION_SERIAL_NUMBER_ERROR = "SerialNumber is required";
 