import request from "./ConfigRequest";

export const pageTransactionMonitorDataDto = async (transactionMonitorDataQueryVo) => {
  return request({
    url: "transactionMonitorData/pageTransactionMonitorDataDto",
    params: {
      index:transactionMonitorDataQueryVo.index,
      pageSize:transactionMonitorDataQueryVo.pageSize,
      id:transactionMonitorDataQueryVo.id ,
      storeId:transactionMonitorDataQueryVo.storeId,
      businessId:transactionMonitorDataQueryVo.businessId  ,
      acquirerTransactionId:transactionMonitorDataQueryVo.acquirerTransactionId  ,
      tradeNumber:transactionMonitorDataQueryVo.tradeNumber ,
   
      creationTimeStart:transactionMonitorDataQueryVo.creationTimeStart  ,
      creationTimeEnd:transactionMonitorDataQueryVo.creationTimeEnd,
      capturedTimeStart:transactionMonitorDataQueryVo.capturedTimeStart  ,
      capturedTimeEnd:transactionMonitorDataQueryVo.capturedTimeEnd,
      externalTransactionId:transactionMonitorDataQueryVo.externalTransactionId ,
      clientTransactionId:transactionMonitorDataQueryVo.clientTransactionId  ,
      paymentLinkTransactionId:transactionMonitorDataQueryVo.paymentLinkTransactionId,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const updateTransactionMonitorDataDto = (transactionMonitorDataVo) => {
  return request({
    url: "transactionMonitorData/updateTransactionMonitorDataDto",
    method: "put",
    data: transactionMonitorDataVo,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const searchTransactionDto = (transactionQueryVo) => {
  return request({
    url: "transaction/pageTransactionDto",
    params:transactionQueryVo,
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  });
};


export const selectTransactionDtoExport = async (transactionQueryVo) => {
  return request({
    url: "transaction/selectTransactionDtoExport",
    params: {
      index:transactionQueryVo.index,
      pageSize:transactionQueryVo.pageSize,
      id:transactionQueryVo.id ,
      storeId:transactionQueryVo.storeId,
      businessId:transactionQueryVo.businessId  ,
      acquirerTransactionId:transactionQueryVo.acquirerTransactionId  ,
      tradeNumber:transactionQueryVo.tradeNumber ,
      paymentStatus:transactionQueryVo.paymentStatus ,
      creationTimeStart:transactionQueryVo.creationTimeStart  ,
      creationTimeEnd:transactionQueryVo.creationTimeEnd,
      capturedTimeStart:transactionQueryVo.capturedTimeStart  ,
      capturedTimeEnd:transactionQueryVo.capturedTimeEnd,
      externalTransactionId:transactionQueryVo.externalTransactionId ,
      clientTransactionId:transactionQueryVo.clientTransactionId,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
    'responseType': 'blob'
  });
};


export const exportPreciseTransactionXlsDtoExport = async (transactionQueryVo) => {
  return request({
    url: "transaction/selectPreciseTransactionDtoExport",
    params: {
      index:transactionQueryVo.index,
      pageSize:transactionQueryVo.pageSize,
      id:transactionQueryVo.id ,
      storeId:transactionQueryVo.storeId,
      businessId:transactionQueryVo.businessId  ,
      acquirerTransactionId:transactionQueryVo.acquirerTransactionId  ,
      tradeNumber:transactionQueryVo.tradeNumber ,
      paymentStatus:transactionQueryVo.paymentStatus ,
      creationTimeStart:transactionQueryVo.creationTimeStart  ,
      creationTimeEnd:transactionQueryVo.creationTimeEnd,
      capturedTimeStart:transactionQueryVo.capturedTimeStart  ,
      capturedTimeEnd:transactionQueryVo.capturedTimeEnd,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
    'responseType': 'blob'
  });
};


export const transactionRefund = (transactionQueryVo) => {
  return request({
    url: "transaction/transactionRefund",
    method: "put",
    data: transactionQueryVo,
    headers: {
      "Content-Type": "application/json",
    },
  });
};