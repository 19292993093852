import React, { useEffect, useState } from "react";
 

import {
  CircularProgress,
  Typography,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Paper,
  Box,
  Container,
  styled,
  Grid,
  makeStyles,
  
} from "@material-ui/core";
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import shadows from "@material-ui/core/styles/shadows";
import StoreIdSelect from "../common/StoreIdSelect";

import {STORE_DCS_CONFIGURATION_STORE_ID_ERROR, STORE_DCS_CONFIGURATION_DCS_MERCHANT_ID_ERROR ,
    STORE_DCS_CONFIGURATION_DCS_TERMINAL_ID_ERROR ,
    STORE_DCS_CONFIGURATION_DCS_SUB_MERCHANT_ID_ERROR ,
     STORE_DCS_CONFIGURATION_DCS_SUB_TERMINAL_ID_ERROR ,
    STORE_DCS_CONFIGURATION_DCS_SUB_MERCHANT_NAME_ERROR ,
    STORE_DCS_CONFIGURATION_SERIAL_NUMBER_ERROR} from "../../components/common/constant/ManageConstant";

import {updateStoreDcsConfiguration} from "../../service/StoreDcsConfigurationRequest";
 
import {ERROR_CODE_SUCCESS} from "../../components/common/constant/ResponseErrorCodeConstant";
import { BigNumber } from "bignumber.js";
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: 0,
    marginBottom: theme.spacing(2),
 
  }));
 
export default function StoreDcsConfigurationEdit({handleEditClose,handleCloseEditUpdate,editStoreDcsStoreConfigurationDto}) {


    
    const [store, setStore] = useState();
    const [storeLabelText,setStoreLabelText] = useState(editStoreDcsStoreConfigurationDto.storeName);
    const [id,setId] = useState(editStoreDcsStoreConfigurationDto.id)
    const [storeId,setStoreId] = useState(editStoreDcsStoreConfigurationDto.storeId);
    const [storeName,setStoreName] = useState(editStoreDcsStoreConfigurationDto.storeName);
    const [dcsMerchantId,setDcsMerchantId] = useState(editStoreDcsStoreConfigurationDto.dcsMerchantId);
    const [dcsTerminalId,setDcsTerminalId] = useState(editStoreDcsStoreConfigurationDto.dcsTerminalId);
    const [dcsSubMerchantId,setDcsSubMerchantId] = useState(editStoreDcsStoreConfigurationDto.dcsSubMerchantId);
    const [dcsSubTerminalId,setDcsSubTerminalId] = useState(editStoreDcsStoreConfigurationDto.dcsSubTerminalId);
    const [dcsSubMerchantName,setDcsSubMerchantName] = useState(editStoreDcsStoreConfigurationDto.dcsSubMerchantName);
    const [serialNumber,setSerialNumber] = useState(editStoreDcsStoreConfigurationDto.serialNumber);

    const [dcsStoreCity,setDcsStoreCity] = useState(editStoreDcsStoreConfigurationDto.dcsStoreCity);
    const [dcsSubMerchantCity,setDcsSubMerchantCity] = useState(editStoreDcsStoreConfigurationDto.dcsSubMerchantCity);
    const [dcsSubMerchantCountryCode,setDcsSubMerchantCountryCode] = useState(editStoreDcsStoreConfigurationDto.dcsSubMerchantCountryCode);
    const [errorMsg,setErrorMsg] = useState();
    const [firstRenderFlag,setFirstRenderFlag] = useState(true);
    const [dcsMerchantCategoryCode,setDcsMerchantCategoryCode] = useState(editStoreDcsStoreConfigurationDto.dcsMerchantCategoryCode);
    const clearSelectStoreId =() => { 
        console.log("Clear storeId");
        setStoreId();
        setStoreName();
        setDcsSubMerchantId();

        if(firstRenderFlag){
          setStoreId(editStoreDcsStoreConfigurationDto.storeId);
          setStoreName(editStoreDcsStoreConfigurationDto.storeName);
          setDcsSubMerchantId(editStoreDcsStoreConfigurationDto.dcsSubMerchantId);
          setFirstRenderFlag(false);
          
        }
      }
    const setStoreIdChange = (storeText) => {
    
        console.log("setStoreIdChange===", storeText);
        if (storeText) {
          setStoreId(storeText.id);
          setStoreName(storeText.name);
          let sid =new BigNumber(storeText.id).toString();
          setDcsSubMerchantId(sid.substr(0,15));
          
        }
      };


      const editStoreDcsConfiguration = async () => {

        try{
        
            if(!storeId){
               
                setErrorMsg(STORE_DCS_CONFIGURATION_STORE_ID_ERROR);
                return;
            }


            if(!dcsMerchantId){
                setErrorMsg(STORE_DCS_CONFIGURATION_DCS_MERCHANT_ID_ERROR);
                return;
            }

            if(!dcsTerminalId){
                setErrorMsg(STORE_DCS_CONFIGURATION_DCS_TERMINAL_ID_ERROR);
                return;
            }

            if(!dcsSubMerchantId){
                setErrorMsg(STORE_DCS_CONFIGURATION_DCS_SUB_MERCHANT_ID_ERROR);
                return;
            }
            if(!dcsSubTerminalId){
                setErrorMsg(STORE_DCS_CONFIGURATION_DCS_SUB_TERMINAL_ID_ERROR);
                return;
            }
            if(!dcsSubMerchantName){
                setErrorMsg(STORE_DCS_CONFIGURATION_DCS_SUB_MERCHANT_NAME_ERROR);
                return;
            }
            if(!serialNumber){
                setErrorMsg(STORE_DCS_CONFIGURATION_SERIAL_NUMBER_ERROR);
                return;
            }
           
            let storeDcsConfigurationVo = {
                id: id,
                storeId:storeId,
                dcsMerchantId:dcsMerchantId,
                dcsTerminalId:dcsTerminalId,
                dcsSubMerchantId:dcsSubMerchantId,
                dcsSubTerminalId:dcsSubTerminalId,
                dcsSubMerchantName:dcsSubMerchantName,
                serialNumber:serialNumber,
                dcsStoreCity:dcsStoreCity,
                dcsSubMerchantCity:dcsSubMerchantCity,
                dcsSubMerchantCountryCode:dcsSubMerchantCountryCode,
                dcsMerchantCategoryCode:dcsMerchantCategoryCode,

            };
            let storeDcsConfigurationResponse=await updateStoreDcsConfiguration(storeDcsConfigurationVo);
            let code = storeDcsConfigurationResponse.code;
            if(ERROR_CODE_SUCCESS==code){
                setErrorMsg();
                handleCloseEditUpdate();
              }else{
                console.log("saveStoreDcsConfiguration===failed");
                setErrorMsg(storeDcsConfigurationResponse.message);
              }

        }catch(e){
            console.log("saveStoreDcsConfiguration===error=={}",e);
        }

        
      }


    return (
        <Box sx={{ flexGrow: 1, overflow: 'hidden', px: 3 ,width:'100%'}}>

            
            <Item elevation={0}>
            <Stack spacing={2} direction="row" alignItems="center">
        

            
            <Stack sx={{ minWidth: 0 ,width:'50%'}}>
            <StoreIdSelect 
                        setStoreIdChange={setStoreIdChange}
                        store = {store}
                        clearSelectStoreId={clearSelectStoreId} 
                        storeLabelText ={storeLabelText}/>
            </Stack>
            
            <Stack sx={{ minWidth: 0 ,width:'50%'}}>
            <Typography variant="subtitle1" component="subtitle1">
            {storeName ? storeName : editStoreDcsStoreConfigurationDto.storeName  }
                </Typography>
            </Stack>

           
           </Stack>
            </Item>
            
        <Item
        elevation={0}
          sx={{
            
            
          }}
        >
          <Stack spacing={2} direction="row" alignItems="center">
          <Stack sx={{ minWidth: 0 ,width:'50%'}}>
            <TextField
                required
                id="standard-required"
                label="DcsMerchantId"
            
                variant="standard"
                value={dcsMerchantId}
                fullWidth
                 onChange={(event) => {
                    setDcsMerchantId(event.target.value);
                }}
                
                /> 
            </Stack>
          <Stack sx={{ minWidth: 0 ,width:'50%'}}>
            <TextField
                required
                id="standard-required"
                label="DcsTerminalId"
            
                variant="standard"

                value={dcsTerminalId}
                fullWidth
                onChange={(event) => {
                    setDcsTerminalId(event.target.value);
                }}
                /> 
            </Stack>


            
          </Stack>
        </Item>

        <Item
        elevation={0}
          sx={{
            
            
          }}
        >
          <Stack spacing={2} direction="row" alignItems="center">
         
          <Stack sx={{ minWidth: 0 ,width:'50%'}}>
          DcsSubMerchantId
            <TextField
                
                id="standard-required"
                
              
            
                variant="standard"
                fullWidth
              
                value={dcsSubMerchantId}
                onChange={(event) => {
                    setDcsSubMerchantId(event.target.value);
                }}
                /> 
            </Stack>
          <Stack sx={{ minWidth: 0 ,width:'50%'}}>
            <TextField
                required
                id="standard-required"
                label="DcsSubTerminalId"
            
                variant="standard"
                fullWidth
                onChange={(event) => {
                    setDcsSubTerminalId(event.target.value);
                }}

                value={dcsSubTerminalId}
                /> 
            </Stack>

          
          </Stack>
        </Item>

        <Item
        elevation={0}
          sx={{
            
            
          }}
        >
          <Stack spacing={2} direction="row" alignItems="center">
          <Stack sx={{ minWidth: 0 ,width:'50%'}}>
            <TextField
                required
                id="standard-required"
                label="DcsSubMerchantName"
            
                variant="standard"
                fullWidth
                
                onChange={(event) => {
                    setDcsSubMerchantName(event.target.value);
                }}

                value={dcsSubMerchantName}
                /> 
            </Stack>

          <Stack sx={{ minWidth: 0 ,width:'50%'}}>
            <TextField
                required
                id="standard-required"
                label="SerialNumber"
            
                variant="standard"
                fullWidth

                onChange={(event) => {
                    setSerialNumber(event.target.value);
                }}

                value={serialNumber}
                /> 
            </Stack>

           
          </Stack>
        </Item>

        <Item
        elevation={0}
          sx={{
            
            
          }}
        >
          <Stack spacing={2} direction="row" alignItems="center">

          <Stack sx={{ minWidth: 0 ,width:'50%'}}>
            <TextField
               
                id="standard-required"
                label="DcsStoreCity"
            
                variant="standard"
                fullWidth


                onChange={(event) => {
                    setDcsStoreCity(event.target.value);
                }}

                value={dcsStoreCity}
                /> 
            </Stack>
          <Stack sx={{ minWidth: 0 ,width:'50%'}}>
            <TextField
                
                id="standard-required"
                label="DcsSubMerchantCity"
            
                variant="standard"
                fullWidth

                onChange={(event) => {
                    setDcsSubMerchantCity(event.target.value);
                }}


                value={dcsSubMerchantCity}
                /> 

              
            </Stack>

           
          </Stack>
        </Item>

        <Item
        elevation={0}
          sx={{
            
            
          }}
        >
          <Stack spacing={2} direction="row" alignItems="center">
          <Stack sx={{ minWidth: 0 ,width:'50%'}}>
            <TextField
                
                id="standard-required"
                label="DcsSubMerchantCountryCode"
            
                variant="standard"
                fullWidth
              

                onChange={(event) => {
                    setDcsSubMerchantCountryCode(event.target.value);
                }}

                value={dcsSubMerchantCountryCode}
                /> 
            </Stack>
            <Stack sx={{ minWidth: 0 ,width:'50%'}}>
            <TextField
                
                id="standard-required"
                label="DcsMCC"
            
                variant="standard"
                fullWidth
 

                onChange={(event) => {
                  setDcsMerchantCategoryCode(event.target.value);
                }}

                value={dcsMerchantCategoryCode}
                /> 
            </Stack>
            
          
          </Stack>

          <Item
        elevation={0}
          sx={{
            
            
          }}
        >
          <Stack spacing={2} direction="row" alignItems="center">
           
            <Stack sx={{ minWidth: 0 ,width:'50%'}}>

            <Typography variant="h4"    >
                {errorMsg}
            </Typography>
            </Stack>
            
          
          </Stack>
        </Item>
        </Item>
        
        <Item elevation={0}>
        <Stack spacing={2} direction="row" alignItems="center">
        <Button variant="outlined"  color="primary" onClick={handleEditClose}  >Cancel</Button>
        <Button variant="outlined"  color="primary"  onClick={editStoreDcsConfiguration}>Save</Button>
            </Stack>
        </Item>

        
      </Box>);
}