 
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
 

import {
    CircularProgress, Typography, TextField,
    Dialog, DialogActions, DialogContent, DialogTitle,
    Button, Paper, Box,  Grid,makeStyles, Input,
} from '@material-ui/core';



import { BigNumber } from "bignumber.js";

import { useForm, Controller } from "react-hook-form";
import StoreIdSelect from "../common/StoreIdSelect";
import Select from "react-select";
import {pageTransactionMonitorDataDto} from "../../service/TransactionMonitorDataRequest";
import TransactionMonitorDataEditForm from "./TransactionMonitorDataEditForm";
import TransactionMonitorDataDetail from "./TransactionMonitorDataDetail";

const useStyles = makeStyles((theme) => ({
    container: {
      display: 'grid',
      gridTemplateColumns: 'repeat(12, 1fr)',
      gridGap: theme.spacing(3),
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      whiteSpace: 'nowrap',
      marginBottom: theme.spacing(1),
    },
    divider: {
      margin: theme.spacing(2, 0),
    },
  }));
  

export default function TransactionMonitorDataTable() {
     
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [data, setData] = useState([["Loading Data..."]]);
    const [count, setCount] = useState(0);
    const [sortOrder, setSortOrder] = useState(0);
    const [creationTimeStart,setCreationTimeStart] = useState();
    const [creationTimeEnd,setCreationTimeEnd] = useState();
    const [capturedTimeStart,setCapturedTimeStart] = useState();
    const [capturedTimeEnd,setCapturedTimeEnd] = useState();
    const [storeId,setStoreId] = useState();
    const [store, setStore] = useState();

    const [storeLabelText,setStoreLabelText] = useState("");

    const [refreshFlag, setRefreshFlag] = useState(false);

    const [externalTransactionId,setExternalTransactionId] = useState();
    const [clientTransactionId,setClientTransactionId] = useState();
    const [paymentLinkTransactionId,setPaymentLinkTransactionId] = useState();
    const [businessId, setBusinessId] = useState();
    const [id,setId] = useState();
    const [transactionId,setTransactionId] = useState();
    const [acquirerTransactionId,setAcquirerTransactionId] = useState();
    const [paymentStatus,setPaymentStatus] = useState();
    const [editTransactionMonitorDataDto, setEditTransactionMonitorDataDto] = useState({});
    const [open, setOpen] = useState(false);
    const [detailOpen,setDetailOpen] = useState(false);

    const classes = useStyles();


  const { control, handleSubmit,reset } = useForm({
    defaultValues: {
      transactionId: '',
      tradeNumber:'',
      acquirerTransactionId:'',
      externalTransactionId:'',
      clientTransactionId:'',
      paymentLinkTransactionId:'',
    }

    
  });

    const configurationData = {
        page: 0,
        count: 1,
        rowsPerPage: 10,
        sortOrder: {},
        data: [["Loading Data..."]],
        columns: [
          {
            name: "id",
            label: "Id",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                // Here you can render a more complex display.
                // You're given access to tableMeta, which has
                // the rowData (as well as the original object data).
    
                if (value) {
                  return new BigNumber(value).toString();
                }
                return value;
              },
            },
          },
          {
            name: "transactionId",
            label: "Transaction Id",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                // Here you can render a more complex display.
                // You're given access to tableMeta, which has
                // the rowData (as well as the original object data).
    
                if (value) {
                  return new BigNumber(value).toString();
                }
                return value;
              },
            },
          },
    
          {
            name: "storeName",
            label: "Store Name",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                // Here you can render a more complex display.
                // You're given access to tableMeta, which has
                // the rowData (as well as the original object data).
                 
                return value;
              },
            },
          },
          {
            name: "buisnessName",
            label: "Company Name",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                // Here you can render a more complex display.
                // You're given access to tableMeta, which has
                // the rowData (as well as the original object data).
                 
                return value;
              },
            },
          },
          {
            name: "amount",
            label: "Amount",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                // Here you can render a more complex display.
                // You're given access to tableMeta, which has
                // the rowData (as well as the original object data).
                // See the console for a detailed look at this object.
    
                
                if (value) {
                  return value ;
                }
                return value;
              },
            },
          },
          // {
          //   name: "serviceCharge",
          //   label: "MDR",
          //   options: {
          //     customBodyRender: (value, tableMeta, updateValue) => {
          //       // Here you can render a more complex display.
          //       // You're given access to tableMeta, which has
          //       // the rowData (as well as the original object data).
          //       // See the console for a detailed look at this object.
    
                
          //       if (value) {
          //         return value ;
          //       }
          //       return value;
          //     },
          //   },
          // },
          {
            name: "paymentStatus",
            label: "Payment Status",
            options: {},
          }, {
            name: "transactionFlag",
            label: "Transaction Flag",
            options: {},
          },
          {
            name: "auditStatus",
            label: "Audit Status",
            options: {},
          },
          {
            name: "auditPerson",
            label: "Auditor",
            options: {},
          },
          {
            name: "capturedTime",
            label: "Payment time",
            options: {},
          },{
            name: "refundStatus",
            label: "Refund status",
            options: {},
          },
          {
            name: "transactionSource",
            label: "Transaction Source",
            options: {},
          },
          {
            name: "acquirerTypeString",
            label: "Acquirer Type",
            options: {},
          },
          {
            name: "acquirerTransactionId",
            label: "Acquirer Transaction Id",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                // Here you can render a more complex display.
                // You're given access to tableMeta, which has
                // the rowData (as well as the original object data).
                if (value) {
                  return value.toString();
                }
                return value;
              },
            },
          },
          {
            name: "externalTransactionId",
            label: "External transaction id",
            options: {},
          },
          {
            name: "clientTransactionId",
            label: "Client transaction id",
            options: {},
          },
          
          // {
          //   name: "creationTime",
          //   label: "Create Time",
          //   options: {},
          // },
           
          // {
          //   name: "modifyTime",
          //   label: "Last update time",
          //   options: {},
          // },
           
          {
            name: "Action",
            options: {
              filter: true,
              sort: false,
              empty: true,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <Box>
                    <Box marginBottom={5}>
                    <Button
                      variant="outlined"
                      size="small"
                      color="primary"
                      onClick={(e) => editTransactionMonitorData(tableMeta)}
                    >
                      Edit
                    </Button>
                    </Box>
                   
                   <Box>
                   <Button
                      variant="outlined"
                      size="small"
                      color="primary"
                      onClick={(e) => detailTransactionMonitorData(tableMeta)}
                    >
                      Detail
                    </Button>
                   </Box>
                    
                  </Box>
                );
              },
            },
          },
           
        ],
      };


      const options = {
        filterType: "dropdown",
        responsive: "vertical",
        serverSide: true,
        count: count,
        rowsPerPage: 10,
        rowsPerPageOptions: [],
        sortOrder: { sortOrder },
        print: false,
        download: false,
        filter: false,
        viewColumns: false,
        onTableChange: (action, tableState) => {
          
      
          // a developer could react to change on an action basis or
          // examine the state as a whole and do whatever they want
    
          switch (action) {
            case "changePage":
              changePage(tableState.page, tableState.sortOrder);
              break;
            case "sort":
              this.sort(tableState.page, tableState.sortOrder);
              break;
            default:
              console.log("action not handled.");
          }
        },
      };


      const setStoreIdChange = (storeText) => {
    
        console.log("setStoreIdChange===", storeText);
        if (storeText) {
          setStoreId(storeText.id);
          
        }
      };


      const clearSelectStoreId =() => { 
        console.log("Clear storeId");
        setStoreId();
      }


  const onTransactionMonitrSubmit = (data) =>{
 
    console.log(data);
    console.log("businessId==",businessId);
    console.log("storeId==",storeId);
    console.log("creationTimeStart==",creationTimeStart);
    console.log("creationTimeEnd==",creationTimeEnd);
    console.log("capturedTimeStart==",capturedTimeStart);
    console.log("capturedTimeEnd==",capturedTimeEnd);
    console.log("externalTransactionId==",externalTransactionId);
    console.log("clientTransactionId==",clientTransactionId);

    setTransactionId(data.transactionId);
    setAcquirerTransactionId(data.acquirerTransactionId); 
   
 
    setExternalTransactionId(data.externalTransactionId);
    setClientTransactionId(data.clientTransactionId);

    let  transactionMonitorDataQueryVo = {
      "index":1,
      "pageSize":10,
      "id":data.transactionId ? data.transactionId: null ,
      "storeId":storeId ? storeId.toString() :null,
      "businessId": businessId ?  businessId : null,
      "acquirerTransactionId":data.acquirerTransactionId ? data.acquirerTransactionId : null,
    
      "creationTimeStart":creationTimeStart ? creationTimeStart:  null,
      "creationTimeEnd":creationTimeEnd ?  creationTimeEnd: null,
      "capturedTimeStart":capturedTimeStart ?   capturedTimeStart: null,
      "capturedTimeEnd":capturedTimeEnd ? capturedTimeEnd: null,
      "externalTransactionId":data.externalTransactionId ? data.externalTransactionId: null,
      "clientTransactionId":data.clientTransactionId ? data.clientTransactionId : null,
      "paymentLinkTransactionId":data.paymentLinkTransactionId ? data.paymentLinkTransactionId : null,
    };
    initTransactionMonitorData(transactionMonitorDataQueryVo);
  } 

  const initTransactionMonitorData = async(transactionMonitorDataQueryVo) => {
    setIsLoading(true);
    try {
 
       
      let initResponseDto = await pageTransactionMonitorDataDto(transactionMonitorDataQueryVo);
      
      console.log("===initTransactionMonitorData=data", initResponseDto.data);
      if (initResponseDto && initResponseDto.data) {
        setData(initResponseDto.data.records);
        setCount(initResponseDto.data.total);
      } 
      setIsLoading(false);

      setPage(transactionMonitorDataQueryVo.index);
       
    }catch(e) {
      console.log("initTransactionMonitorData==error", e);
    }
   
    setIsLoading(false);
  };

  const changePage = (page, sortOrder) => {
    setIsLoading(true);
 
    console.log("=changePage==page", page);
    page = page + 1;
    
    let  transactionMonitorDataQueryVo = {
      "index":page,
      "pageSize":10,
      "id":id ? id: null ,
      storeId:storeId ? new BigNumber(storeId).toString() :null,
       
      "acquirerTransactionId":acquirerTransactionId ? acquirerTransactionId : null,

      "paymentStatus":paymentStatus? paymentStatus : null,
      "creationTimeStart":creationTimeStart ? creationTimeStart:  null,
      "creationTimeEnd":creationTimeEnd ?  creationTimeEnd: null,
      "capturedTimeStart":capturedTimeStart ?   capturedTimeStart: null,
      "capturedTimeEnd":capturedTimeEnd ? capturedTimeEnd: null,
      "externalTransactionId":externalTransactionId ? externalTransactionId: null,
      "clientTransactionId":clientTransactionId ? clientTransactionId : null,
    };
    initTransactionMonitorData(transactionMonitorDataQueryVo);
  };

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the document title using the browser API
    let  transactionQueryVo = {
      "index":1,
      "pageSize":10,
    };
    initTransactionMonitorData(transactionQueryVo);
    setRefreshFlag(false);
  }, [refreshFlag]);

  useEffect(()=>{
    let  transactionMonitorDataQueryVo = {
        "index":1,
        "pageSize":10,
      };
      initTransactionMonitorData(transactionMonitorDataQueryVo);
    
  },[]);


  const transactionMonitorReset = () => {
    reset();
    setBusinessId();
    setStoreId();
    setCreationTimeStart();
    setCreationTimeEnd();
    setCapturedTimeStart();
    setCapturedTimeEnd();
    setExternalTransactionId();
    setClientTransactionId();
    setPaymentLinkTransactionId();
   
  }

  const creationTimeStartChange =(startTime) => {
    console.log("creationTimeStartChange==", startTime);
    startTime = startTime + " 00:00:00";
    setCreationTimeStart(startTime);
  }

  const creationTimeEndChange =(endTime) => {
    console.log("creationTimeEndChange==", endTime);
    endTime = endTime + " 00:00:00";
    setCreationTimeEnd(endTime);
  }

  const capturedTimeStartChange =(startTime) => {
    console.log("capturedTimeStartChange==", startTime);
    startTime = startTime + " 00:00:00";
    setCapturedTimeStart(startTime);
  }

  const capturedTimeEndChange =(endTime) => {
    console.log("capturedTimeEndChange===", endTime);
    endTime = endTime + " 00:00:00";
    setCapturedTimeEnd(endTime);
  }


  const editTransactionMonitorData = (tableMeta) => {
  
    const row = tableMeta.rowIndex;
    console.log("row", row);
    setOpen(!open);
    const editTransactionDto = data[row];
    setEditTransactionMonitorDataDto(editTransactionDto);
  };

  const detailTransactionMonitorData = (tableMeta) => {
  
    const row = tableMeta.rowIndex;
    console.log("row", row);
    setDetailOpen(!detailOpen);
    const editTransactionDto = data[row];
    setEditTransactionMonitorDataDto(editTransactionDto);
  };

  const handleClose = () => {
    console.log("======handle close");
    setOpen(!open);
 
  };

  const handleDetailClose = () => {
    
    setDetailOpen(!detailOpen);
 
  };

  const handleEditSubmit = () => {
    console.log("======handle edit submit");
    setOpen(!open);
  

    setTimeout(() => {
      setRefreshFlag(true);
    }, 10);
  };

    return (
        <div>

<form >
      <Grid container spacing={3}>
        
        <Grid item xs={4}>
          <Paper className={classes.paper}>Id:
            <Controller
            name="transactionId"
            control={control}
            render={({ field }) => <Input {...field} />}
            />
         </Paper>
        </Grid>
        {/* <Grid item xs={4}>
          <Paper className={classes.paper}> 
             <label>Payment Status</label>
          <Controller
            name="paymentStatus"
            control={control}
            defaultValue=""
            render={({ field }) => <Select 
              {...field} 
              options={[
                { value: "success", label: "success" },
                { value: "cancel", label: "cancel" },
                { value: "fail", label: "fail" }
              ]} 
            />
            }
          />
       
        </Paper>
        </Grid> */}
        {/* <Grid item xs={4}>
          <Paper className={classes.paper}> 
        <TextField
        id="creationTimeStart"
        name="creationTimeStart"
        label="Create time start"
        control={control}
        type="date"
        defaultValue={creationTimeStart}
         
        sx={{ width: 220 }}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e) => creationTimeStartChange(e.target.value)}
      />

   

      
      </Paper>
        </Grid> */}

        {/* <Grid item xs={4}>
          <Paper className={classes.paper}>
            
            <TextField
            id="creationTimeEnd"
            name="creationTimeEnd"
            label="Create time end"
            type="date"
            defaultValue={creationTimeEnd}
            sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => creationTimeEndChange(e.target.value)}
            
          />
            </Paper>
        </Grid> */}
        {/* <Grid item xs={4}>
          <Paper className={classes.paper}>TradeNumber:
          <Controller
            name="tradeNumber"
            control={control}
            render={({ field }) => <Input {...field} />}
            />
          </Paper>
        </Grid> */}
        <Grid item xs={4}>
          <Paper className={classes.paper}>AcquirerTransactionId:<Controller
            name="acquirerTransactionId"
            control={control}
            render={({ field }) => <Input {...field} />}
            /></Paper>
        </Grid>
        <Grid item xs={4}>
              <Paper className={classes.paper}> <TextField
            id="capturedTimeStart"
            name="capturedTimeStart"
            label="Pay time start"
            control={control}
            type="date"
            defaultValue={capturedTimeStart}
            sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => capturedTimeStartChange(e.target.value)}
          />
          </Paper>
        </Grid>
        <Grid item xs={4}>
            <Paper className={classes.paper}>
          <TextField
          id="capturedTimeEnd"
          name="capturedTimeEnd"
          label="Pay time end"
          control={control}
          type="date"
          defaultValue="{capturedTimeEnd}"
          sx={{ width: 220 }}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => capturedTimeEndChange(e.target.value)}
        />
        </Paper>
      </Grid>
      <Grid item xs={4}>
          <Paper className={classes.paper}> 
            <StoreIdSelect 
                        setStoreIdChange={setStoreIdChange}
                        store = {store}
                        clearSelectStoreId={clearSelectStoreId} 
                        storeLabelText ={storeLabelText}/>
            </Paper>
        </Grid>
        <Grid item xs={4}>

        <Paper className={classes.paper}>External Transaction Id:<Controller
            name="externalTransactionId"
            control={control}
            render={({ field }) => <Input {...field} />}
            /></Paper>
        </Grid>
        <Grid item xs={4}>

      <Paper className={classes.paper}>Client Transaction Id:<Controller
          name="clientTransactionId"
          control={control}
          render={({ field }) => <Input {...field} />}
          /></Paper>
      </Grid>
      {/* <Grid item xs={4}>

<Paper className={classes.paper}>Payment Link Transaction Id:<Controller
    name="paymentLinkTransactionId"
    control={control}
    render={({ field }) => <Input {...field} />}
    /></Paper>
</Grid>
   */}
        <Grid item xs={4}>
        <Paper className={classes.paper}><Button onClick={handleSubmit(onTransactionMonitrSubmit)} variant={"outlined"} color="primary">Search</Button></Paper>
        </Grid>
        <Grid item xs={4}>
        <Paper className={classes.paper}><Button onClick={() => transactionMonitorReset()} variant={"outlined"} color="primary">Reset</Button></Paper>
        </Grid>

      
        <Grid item xs={4}>
        <Paper className={classes.paper}><Button onClick={() => exportTransactionXls()} variant={"outlined"} color="primary">Export Detail excel </Button></Paper>
        </Grid>

      </Grid>
      </form>

           <MUIDataTable
        title={
          <Typography variant="h6">
            Transaction monitor list
            {isLoading && (
              <CircularProgress
                size={24}
                style={{ marginLeft: 15, position: "relative", top: 4 }}
              />
            )}
          </Typography>
        }
        data={data}
        columns={configurationData.columns}
        options={options}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "100%",
            height: "80%",
          },
        }}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Edit transaction monitor data</DialogTitle>
        <DialogContent>
          <TransactionMonitorDataEditForm
            handleEditClose={handleClose}
            handleEditSubmit={handleEditSubmit}
            editTransactionDto={editTransactionMonitorDataDto}
          />
        </DialogContent>
      </Dialog>


      <Dialog
        open={detailOpen}
        onClose={handleDetailClose}
        PaperProps={{
          style: {
            width: "100%",
            height: "80%",
          },
        }}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Transaction monitor data detail</DialogTitle>
        <DialogContent>
          <TransactionMonitorDataDetail
            handleEditClose={handleDetailClose}
            handleEditSubmit={handleEditSubmit}
            editTransactionDto={editTransactionMonitorDataDto}
          />
        </DialogContent>
      </Dialog>
        </div>
    );
}
