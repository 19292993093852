import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
 

import {
  CircularProgress,
  Typography,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Paper,
  Box,
  Container,
  styled,
  Grid,
  makeStyles,
  
} from "@material-ui/core";
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import DialogContentText from '@mui/material/DialogContentText';
import {pageStoreDcsConfigurationQueryDto,deleteStoreDcsConfigurationDto} from "../../service/StoreDcsConfigurationRequest";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
 
import {ERROR_CODE_SUCCESS} from "../../components/common/constant/ResponseErrorCodeConstant";
import StoreDcsConfigurationAdd from "./StoreDcsConfigurationAdd";
import { BigNumber } from "bignumber.js";
import StoreDcsConfigurationEdit from "./StoreDcsConfigurationEdit";
 
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

 
export default function StoreDcsConfigurationTable() {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [data, setData] = useState([["Loading Data..."]]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState(0);
  const [editOpen, setEditOpen] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);

  const [addOpen, setAddOpen] = useState(false);
  const [
    editStoreDcsStoreConfigurationDto,
    setEditStoreDcsStoreConfigurationDto,
  ] = useState({});

  const [refreshFlag, setRefreshFlag] = useState(false);

  const [searchName, setSearchName] = useState("");

  const [searchRefreshFlag, setSearchRefreshFlag] = useState(false);

  const [id, setId] = useState();
  const [companyNameLike,setCompanyNameLike] = useState();
  const [storeNameLike, setStoreNameLike] = useState();

  const [dcsMerchantId, setDcsMerchantId] = useState();
  const [dcsTerminalId, setDcsTerminalId] = useState();
  const [dcsSubMerchantId, setDcsSubMerchantId] = useState();

  const [dcsSubMerchantNameLike, setDcsSubMerchantNameLike] = useState();
  const [dcsSubTerminalId, setDcsSubTerminalId] = useState();
  const [serialNumberLike, setSerialNumberLike] = useState()

  const [deleteOpen,setDeleteOpen] = useState(false);

  const [deleteId,setDeleteId] = useState();

  const [deleteStoreName,setDeleteStoreName] = useState();
  const [deleteSerialNumber,setDeleteSerialNumber] = useState();


  const searchStoreAcquirerSettlementSummary = () => {
    console.log("======searchStoreAcquirerSettlementSummary", searchName);
    setSearchRefreshFlag(true);
  };

  const addStoreAcquirerSettlementSummary = () => {
    console.log("======handle add addStoreAcquirerSettlementSummary");
    setAddOpen(!addOpen);
  };

  const handleEditClose = () => {
     console.log("======handleEditClose==");
    setEditOpen(!editOpen);
  };

  const handleCloseAdd = () => {
    console.log("======handleCloseAdd");
    setOpenAdd(!openAdd);
  };

  const handleCloseAddSave = () => {
     
    setOpenAdd(!openAdd);
    let storeDcsConfigurationQueryVo = {
      index:1, 
      pageSize:10,
       storeNameLike: storeNameLike ? storeNameLike : null,
       businessNameLike:companyNameLike ? companyNameLike : null,
       serialNumberLike: serialNumberLike ? serialNumberLike :null,
       dcsSubMerchantNameLike:dcsSubMerchantNameLike ? dcsSubMerchantNameLike :null,
       dcsMerchantId:dcsMerchantId ? dcsMerchantId: null ,
       dcsTerminalId:dcsTerminalId ? dcsTerminalId: null ,
       dcsSubMerchantId:dcsSubMerchantId ? dcsSubMerchantId: null ,
       dcsSubTerminalId:dcsSubTerminalId ? dcsSubTerminalId : null,
       id: id ? id : null,
  };
  initStoreDcsConfigurationData(storeDcsConfigurationQueryVo);
  };

  const handleEditSubmit = () => {
    console.log("======handle edit submit");
    setEditOpen(!editOpen);
   

    setTimeout(() => {
      setRefreshFlag(true);
    }, 1000);
  };

  const changePage = (page, sortOrder) => {
    setIsLoading(true);
  
    console.log("===page", page);
    page = page + 1;

    let storeDcsConfigurationQueryVo = {
      index:page, 
      pageSize:rowsPerPage,
       storeNameLike: storeNameLike ? storeNameLike : "",
       businessNameLike:companyNameLike ? companyNameLike : "",
       serialNumberLike: serialNumberLike ? serialNumberLike :"",
       dcsSubMerchantNameLike:dcsSubMerchantNameLike ? dcsSubMerchantNameLike :"",
       dcsMerchantId:dcsMerchantId ? dcsMerchantId: null ,
       dcsTerminalId:dcsTerminalId ? dcsTerminalId: null ,
       dcsSubMerchantId:dcsSubMerchantId ? dcsSubMerchantId: null ,
       dcsSubTerminalId:dcsSubTerminalId ? dcsSubTerminalId : null,
       id: id ? id : "",
  };
    initStoreDcsConfigurationData(storeDcsConfigurationQueryVo);
  };

  const editStoreDcsConfigurationData = (tableMeta) => {
     
    const row = tableMeta.rowIndex;
    console.log("row", row);
    setEditOpen(!editOpen);
    const editStoreDcsConfigurationDto = data[row];
    setEditStoreDcsStoreConfigurationDto(
      editStoreDcsConfigurationDto,
    );

 

    
  };
  console.log("count===", count);
  const options = {
    filterType: "dropdown",
    responsive: "vertical",
    serverSide: true,
    count: count,
    rowsPerPage: 10,
    rowsPerPageOptions: [],
    sortOrder: { sortOrder },
    print: false,
    download: false,
    filter: false,
    viewColumns: false,
    onTableChange: (action, tableState) => {
      console.log(action, tableState);

      // a developer could react to change on an action basis or
      // examine the state as a whole and do whatever they want

      switch (action) {
        case "changePage":
          changePage(tableState.page, tableState.sortOrder);
          break;
        case "sort":
          this.sort(tableState.page, tableState.sortOrder);
          break;
        default:
          console.log("action not handled.");
      }
    },
  };

  const initStoreDcsConfigurationData = async(storeDcsConfigurationQueryVo) => {
    setIsLoading(true);

 
   
    try{

      console.log("=initStoreDcsConfigurationData====storeDcsConfigurationQueryVo==",storeDcsConfigurationQueryVo)
       
      let storeDcsConfigurationResponse = await pageStoreDcsConfigurationQueryDto(storeDcsConfigurationQueryVo);

      console.log("initStoreDcsConfigurationData====storeDcsConfigurationResponse==",storeDcsConfigurationResponse)
      let code = storeDcsConfigurationResponse.code;
      
      let data = storeDcsConfigurationResponse.data;
      if(ERROR_CODE_SUCCESS==code){
        if (data) {
          setData(data.records);
          setCount(data.total);
        }
      }else{
        console.log("initStoreDcsConfigurationData===load failed");
      }

      setPage(storeDcsConfigurationQueryVo.index);
    }catch(e){
      console.log("initStoreDcsConfigurationData====error==",e)
    }
    
    setIsLoading(false);
  };

   
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the document title using the browser API
    let storeDcsConfigurationQueryVo = {
        index:page, 
        pageSize:10
    };
    initStoreDcsConfigurationData(storeDcsConfigurationQueryVo);
  }, [refreshFlag]);

  useEffect(() => {
 
    console.log("===searchStoreAcquirerSettlementSummaryData=");
    if (searchRefreshFlag) {
      console.log("searchName===", searchName);
    }

    if (!searchName) return;
    

    setSearchRefreshFlag(false);
  }, [searchRefreshFlag]);


  const storeDcsConfigurationSubmit = (e) => {
      e.preventDefault();
      let storeDcsConfigurationQueryVo = {
        index:1, 
        pageSize:rowsPerPage,
         storeNameLike: storeNameLike ? storeNameLike : null,
         businessNameLike:companyNameLike ? companyNameLike : null,
         serialNumberLike: serialNumberLike ? serialNumberLike :null,
         dcsSubMerchantNameLike:dcsSubMerchantNameLike ? dcsSubMerchantNameLike :null,
         dcsMerchantId:dcsMerchantId ? dcsMerchantId: null ,
         dcsTerminalId:dcsTerminalId ? dcsTerminalId: null ,
         dcsSubMerchantId:dcsSubMerchantId ? dcsSubMerchantId: null ,
         dcsSubTerminalId:dcsSubTerminalId ? dcsSubTerminalId : null,
         id: id ? id : null,
    };
    initStoreDcsConfigurationData(storeDcsConfigurationQueryVo);
  }


  const storeDcsConfigurationSubmitReset = ()=>{
    console.log("===storeDcsConfigurationSubmitReset====");
    setId();
    setCompanyNameLike();
    setStoreNameLike();
    setDcsMerchantId();
    setDcsTerminalId();
    setDcsSubMerchantId();
    setDcsSubMerchantId();
    setDcsSubMerchantNameLike();
    setSerialNumberLike();
  }

  const addStoreDcsConfiguration = ()=>{

    setOpenAdd(true);
  }


  const handleDeleteClose = ()=>{
    setDeleteOpen(!deleteOpen);
    setDeleteId();
    setDeleteStoreName();
    setDeleteSerialNumber();
  }

  const handleDeleteCloseData = (tableMeta)=>{
    setDeleteOpen(!deleteOpen);
    const row = tableMeta.rowIndex;
  
     
    const editStoreDcsConfigurationDto = data[row];
    setDeleteId(editStoreDcsConfigurationDto.id);
    setDeleteStoreName(editStoreDcsConfigurationDto.storeName);
    setDeleteSerialNumber(editStoreDcsConfigurationDto.serialNumber);
  }

  const deleteDcsConfigurationDto = async()=>{
      try{


        let storeDcsConfigurationVo = {id:deleteId};
       
        let deleteResponse = await deleteStoreDcsConfigurationDto(storeDcsConfigurationVo);

        handleDeleteClose();

        let storeDcsConfigurationQueryVo = {
          index:1, 
          pageSize:10,
           storeNameLike: storeNameLike ? storeNameLike : null,
           businessNameLike:companyNameLike ? companyNameLike : null,
           serialNumberLike: serialNumberLike ? serialNumberLike :null,
           dcsSubMerchantNameLike:dcsSubMerchantNameLike ? dcsSubMerchantNameLike :null,
           dcsMerchantId:dcsMerchantId ? dcsMerchantId: null ,
           dcsTerminalId:dcsTerminalId ? dcsTerminalId: null ,
           dcsSubMerchantId:dcsSubMerchantId ? dcsSubMerchantId: null ,
           dcsSubTerminalId:dcsSubTerminalId ? dcsSubTerminalId : null,
           id: id ? id : null,
      };
      initStoreDcsConfigurationData(storeDcsConfigurationQueryVo);
      }catch(e){
        console.log("Failed to delete=={}",e);
      }
  }

  const handleCloseEditUpdate = ()=>{
    setEditOpen(false);

    let storeDcsConfigurationQueryVo = {
      index:0, 
      pageSize:10,
       storeNameLike: storeNameLike ? storeNameLike : null,
       businessNameLike:companyNameLike ? companyNameLike :null,
       serialNumberLike: serialNumberLike ? serialNumberLike :null,
       dcsSubMerchantNameLike:dcsSubMerchantNameLike ? dcsSubMerchantNameLike :null,
       dcsMerchantId:dcsMerchantId ? dcsMerchantId: null ,
       dcsTerminalId:dcsTerminalId ? dcsTerminalId: null ,
       dcsSubMerchantId:dcsSubMerchantId ? dcsSubMerchantId: null ,
       dcsSubTerminalId:dcsSubTerminalId ? dcsSubTerminalId : null,
       id: id ? id : null,
  };
  initStoreDcsConfigurationData(storeDcsConfigurationQueryVo);
  }
  const configurationData = {
    page: 0,
    count: 1,
    rowsPerPage: 10,
    sortOrder: {},
    data: [["Loading Data..."]],
    columns: [
      {
        name: "id",
        label: "id",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            // Here you can render a more complex display.
            // You're given access to tableMeta, which has
            // the rowData (as well as the original object data).
            if (value) {
              return new BigNumber(value).toString();
            }
            return value;
           
          },
        },
      },
      {
        name: "businessName",
        label: "Company Name",
        options: {},
      },
      {
        name: "storeName",
        label: "Store Name",
        options: {},
      },
      
      {
        name: "dcsMerchantId",
        label: "Dcs Merchant Id",
        options: {},
      },
      {
        name: "dcsTerminalId",
        label: "Dcs Terminal Id",
        options: {},
      },
      {
        name: "dcsSubMerchantCity",
        label: "Dcs Sub Merchant City",
        options: {},
      },
      {
        name: "dcsSubMerchantId",
        label: "Dcs Sub Merchant Id",
        options: {},
      },
      {
        name: "dcsSubTerminalId",
        label: "Dcs Sub Terminal Id",
        options: {},
      },
      {
        name: "dcsSubMerchantName",
        label: "Dcs Sub Merchant Name",
        options: {},
      },
      {
        name: "dcsSubMerchantCountryCode",
        label: "Dcs Sub Merchant Country Code",
        options: {},
      },
      {
        name: "serialNumber",
        label: "Serial Number",
        options: {},
      },
      {
        name: "dcsMerchantCategoryCode",
        label: "Dcs MCC",
        options: {},
      },
      {
        name: "Action",
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Box>
               
               

                <Stack direction="row" spacing={2}>
               <Item elevation={0}>  <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={(e) =>
                    editStoreDcsConfigurationData(tableMeta)
                  }
                >
                  Edit
                </Button></Item>
                <Item elevation={0}> <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={(e) =>
                    handleDeleteCloseData(tableMeta)
                  }
                >
                  Delete
                </Button></Item>
        
      </Stack>

              </Box>
            );
          },
        },
      },
    ],
  };

  return (
    <div>
     
      <Box  component="form"   autoComplete="off"  sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }} onSubmit={storeDcsConfigurationSubmit} >
      <Grid container item xs={12}  spacing={3}  >
        <Grid item xs={4}>
          <Item>
            <TextField
          
            id="id"
            label="Id"  
            value={id}
            onChange={(event) => {
              setId(event.target.value);
            }}
        />
        </Item>
        </Grid>
        <Grid item xs={4}>
            <Item>
            <TextField
          
            id="companyName"
            label="Company Name"  
            value={companyNameLike}
            onChange={(event) => {
              setCompanyNameLike(event.target.value);
            }}
        />
        </Item>
        </Grid>
        <Grid item xs={4}>
          <Item>
          <TextField
          id="storeName"
          label="Store Name"  
          value={storeNameLike}
          onChange={(event) => {
              setStoreNameLike(event.target.value);
            }}
          />
       </Item>
        </Grid>
        <Grid item xs={4}>
          <Item>
       <TextField
          
          id="dcsMerchantId"
          label="Dcs Merchant Id"
          value={dcsMerchantId}
          onChange={(event) => {
              setDcsMerchantId(event.target.value);
            }}  
      /></Item>
        </Grid>

        <Grid item xs={4}>
          <Item> <TextField
          
          id="dcsTerminalId"
          label="Dcs Terminal Id"  
          value={dcsTerminalId}
          onChange={(event) => {
              setDcsTerminalId(event.target.value);
            }}  
      /></Item>
        </Grid>

        <Grid item xs={4}>
          <Item> <TextField
          
          id="dcsSubMerchantId"
          label="Dcs sub Merchant Id"  

          value={dcsSubMerchantId}
          onChange={(event) => {
              setDcsSubMerchantId(event.target.value);
            }}  
      /></Item>
        </Grid>

        <Grid item xs={4}>
          <Item> <TextField
          
          id="dcsSubTerminalId"
          label="Dcs sub Terminal Id"  

          value={dcsSubTerminalId}
          onChange={(event) => {
              setDcsSubTerminalId(event.target.value);
            }}  
      /></Item>
        </Grid>

        <Grid item xs={4}>
          <Item> <TextField
          
          id="dcsSubMerchantName"
          label="DcsSubMerchantName"  

          value={dcsSubMerchantNameLike}
          onChange={(event) => {
              setDcsSubMerchantNameLike(event.target.value);
            }} 
      /></Item>
        </Grid>
        <Grid item xs={4}>
          <Item> <TextField
          
          id="serialNumber"
          label="Serial Number"  
          value={serialNumberLike}
          onChange={(event) => {
              setSerialNumberLike(event.target.value);
            }} 
      /></Item>
        </Grid>

        <Grid item xs={4}>
          <Item><Button variant="outlined" type="submit" color="primary">Search</Button></Item>
        </Grid>
        <Grid  item xs={4}>
          <Item><Button variant="outlined" type="reset" color="primary" onClick={storeDcsConfigurationSubmitReset}>Reset</Button></Item>
        </Grid>
        <Grid item xs={4}>
          <Item><Button variant="outlined"  color="primary" onClick={addStoreDcsConfiguration}>Add</Button></Item>
        </Grid>
      </Grid>
    </Box>
   
     
       
       

      <MUIDataTable
        title={
          <Typography variant="h6">
           Store dcs configuration
            {isLoading && (
              <CircularProgress
                size={24}
                style={{ marginLeft: 15, position: "relative", top: 4 }}
              />
            )}
          </Typography>
        }
        data={data}
        columns={configurationData.columns}
        options={options}
      />

      <Dialog
        open={editOpen}
        onClose={handleEditClose}
        PaperProps={{
          style: {
            width: "100%",
            height: "80%",
          },
        }}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Edit Store dcs configuration</DialogTitle>
        <DialogContent>
          <StoreDcsConfigurationEdit handleEditClose={handleEditClose} handleCloseEditUpdate ={handleCloseEditUpdate} editStoreDcsStoreConfigurationDto={editStoreDcsStoreConfigurationDto} />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openAdd}
        onClose={handleCloseAdd}
        PaperProps={{
          style: {
            width: "100%",
            height: "80%",
          },
        }}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Store dcs configuration add</DialogTitle>
        <DialogContent>
           
           <StoreDcsConfigurationAdd handleCloseAdd={handleCloseAdd} handleCloseAddSave={handleCloseAddSave}/>
        </DialogContent>
        <DialogActions  >
       
        </DialogActions>
      </Dialog>

      
      <Dialog
        open={deleteOpen}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
      <DialogTitle id="alert-dialog-title">
      Are you sure you want to delete this   
        </DialogTitle>
        <DialogContent>

          <Typography variant="subtitle1" gutterBottom>
          {deleteStoreName}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
          SerialNmber:{deleteSerialNumber} ?
          </Typography>
           
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose}>NO</Button>
          <Button onClick={deleteDcsConfigurationDto} autoFocus>
            YES
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
