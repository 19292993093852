import request from "./ConfigRequest";

export const pageStoreDcsConfigurationQueryDto = (storeDcsConfigurationQueryVo) => {
  return request({
    url: "storeDcsConfiguration/pageStoreDcsConfigurationQueryDto",
    params:{
      index:storeDcsConfigurationQueryVo.index,
      pageSize:storeDcsConfigurationQueryVo.pageSize,
      id:storeDcsConfigurationQueryVo.id,
      storeNameLike: storeDcsConfigurationQueryVo.storeNameLike ,
      businessNameLike:storeDcsConfigurationQueryVo.businessNameLike ,
      serialNumberLike: storeDcsConfigurationQueryVo.serialNumberLike ,
      dcsSubMerchantNameLike:storeDcsConfigurationQueryVo.dcsSubMerchantNameLike ,
      dcsMerchantId:storeDcsConfigurationQueryVo.dcsMerchantId ,
      dcsTerminalId:storeDcsConfigurationQueryVo.dcsTerminalId  ,
      dcsSubMerchantId:storeDcsConfigurationQueryVo.dcsSubMerchantId  ,
      dcsSubTerminalId:storeDcsConfigurationQueryVo.dcsSubTerminalId,
      
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const saveStoreDcsConfigurationDto = (storeDcsConfigurationVo) => {
  return request({
    url: "storeDcsConfiguration/saveStoreDcsConfiguration",
    method: "post",
    data: storeDcsConfigurationVo,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const updateStoreDcsConfiguration = (storeDcsConfigurationVo) => {
  return request({
    url: "storeDcsConfiguration/updateStoreDcsConfiguration",
    method: "put",
    data: storeDcsConfigurationVo,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const deleteStoreDcsConfigurationDto = (storeDcsConfigurationVo) => {
  return request({
    url: "storeDcsConfiguration/deleteStoreDcsConfigurationDto",
    method: "delete",
    data: storeDcsConfigurationVo,
    headers: {
      "Content-Type": "application/json",
    },
  });
};